import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import TermsBox from "../components/TermsBox";

const Terms = () => {
  return (
    <Layout>
      <Seo title="Terms of Service" description="noindex" />
      <TermsBox />
    </Layout>
  );
};

export default Terms;
