import React from "react";
import SectionTitle from "./SectionTitle";

export default function PrivacyPolicyBox() {
  return (
    <section className="pt-[170px] pb-28">
      <div className="container">
        <SectionTitle
          title="Privacy and Policy"
          paragraph="Before sign you up to FormBold & using our products and services make sure you read, understood and agreed all of our terms. By using our service, you’ve accepted the terms of use given below."
        />

        <div className="mb-12">

<h3 class="font-bold text-2xl md:text-3xl text-black mb-6">User Data: </h3>
<p class="mt-6 mb-6">All of our registered users informations ( Name and Email ) are secure to us. We are committed to take caring of information and we are promised to our customers that we are never going to share their information with anyone. You can check out detailed explanation of privacy policy from Privacy Policy.</p>
<h3 class="font-bold text-2xl md:text-3xl text-black mt-6 mb-6">Payment and Card Processing: </h3>
<p class="mt-6 mb-6">Our order process is conducted by our online reseller Paddle.com. Paddle.com is the Merchant of Record for all our orders. Paddle provides all customer service inquiries and handles returns. We do not store any credit card information in server, all payments are processed by world leading payment gateways and our site is secured by SSL encryption.</p>
<h3 class="font-bold text-2xl md:text-3xl text-black mt-6 mb-6">Support: </h3>
<p class="mt-6 mb-6">If anyone have any problem with our products are welcome to our support to ask for help. We try to answer the questions as first as possible. Be specific and tell as much details as you can.</p>
</div>
</div>
    </section>
  );
}
